import {ScmAuth, scmAuthApiRef, ScmIntegrationsApi, scmIntegrationsApiRef} from '@backstage/integration-react';
import {
  AnyApiFactory,
  ApiRef,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  errorApiRef,
  fetchApiRef,
  githubAuthApiRef,
  IconComponent,
  identityApiRef,
  oauthRequestApiRef,
  storageApiRef
} from '@backstage/core-plugin-api';
import {shortcutsApiRef} from '@backstage-community/plugin-shortcuts';
import {UserSettingsStorage} from '@backstage/plugin-user-settings';
import {UserSettingsShortcuts} from './factories/userSettingsShortcuts';
import {OAuth2} from '@backstage/core-app-api';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';

export const velaDevAuthApiRef: ApiRef<OAuth2> = createApiRef({
  id: 'auth.kubevela.dev'
});
export const velaPreviewAuthApiRef: ApiRef<OAuth2> = createApiRef({
  id: 'auth.kubevela.preview'
});
export const velaLiveAuthApiRef: ApiRef<OAuth2> = createApiRef({
  id: 'auth.kubevela.live'
});

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: {configApi: configApiRef},
    factory: ({configApi}) => ScmIntegrationsApi.fromConfig(configApi)
  }),
  createApiFactory({
    api: scmAuthApiRef,
    deps: {githubAuthApi: githubAuthApiRef},
    factory: ({githubAuthApi}) => ScmAuth.forGithub(githubAuthApi)
  }),
  createApiFactory({
    api: storageApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      errorApi: errorApiRef,
      fetchApi: fetchApiRef,
      identityApi: identityApiRef
    },
    factory: deps => UserSettingsStorage.create(deps)
  }),
  createApiFactory({
    api: shortcutsApiRef,
    deps: {discoveryApi: discoveryApiRef, identityApi: identityApiRef},
    factory: ({discoveryApi, identityApi}) => new UserSettingsShortcuts(discoveryApi, identityApi)
  }),
  createApiFactory({
    api: velaDevAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef
    },
    factory: ({discoveryApi, oauthRequestApi, configApi}) =>
      OAuth2.create({
        configApi,
        discoveryApi,
        oauthRequestApi,
        provider: {
          id: 'kubevela-dev',
          title: 'Kubevela Dev',
          icon: DirectionsBoatIcon as IconComponent
        },
        environment: configApi.getOptionalString('auth.environment')
      })
  }),
  createApiFactory({
    api: velaPreviewAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef
    },
    factory: ({discoveryApi, oauthRequestApi, configApi}) =>
      OAuth2.create({
        configApi,
        discoveryApi,
        oauthRequestApi,
        provider: {
          id: 'kubevela-preview',
          title: 'Kubevela Preview',
          icon: DirectionsBoatIcon as IconComponent
        },
        environment: configApi.getOptionalString('auth.environment')
      })
  }),
  createApiFactory({
    api: velaLiveAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef
    },
    factory: ({discoveryApi, oauthRequestApi, configApi}) =>
      OAuth2.create({
        configApi,
        discoveryApi,
        oauthRequestApi,
        provider: {
          id: 'kubevela-live',
          title: 'Kubevela Live',
          icon: DirectionsBoatIcon as IconComponent
        },
        environment: configApi.getOptionalString('auth.environment')
      })
  })
];
