import React, {PropsWithChildren, useEffect} from 'react';
import {makeStyles} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import BuildIcon from '@material-ui/icons/Build';
import {LogoFull} from '@bdrck/portal-theme';
import {LogoIcon} from '@bdrck/portal-theme';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import {SidebarSearchModal} from '@backstage/plugin-search';
import {
  CatalogIcon,
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ExploreIcon from '@material-ui/icons/Explore';
import {Shortcuts} from '@backstage-community/plugin-shortcuts';
import {FeatureFlagged} from '@backstage/core-app-api';
import {configApiRef, useApi} from '@backstage/core-plugin-api';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const {isOpen} = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull/> : <LogoIcon/>}
      </Link>
    </div>
  );
};

declare global {
  interface Window { _mfq: any; }
}

export const Root = ({children}: PropsWithChildren<{}>) => {
  const configApi = useApi(configApiRef);
  const src = configApi.getOptional<string>('mouseflow.src') ?? '';


  useEffect(() => {
    if (src !== '') {
      window._mfq = window._mfq || [];

      const mf = document.createElement('script');
      mf.type = 'text/javascript';
      mf.defer = true;
      mf.src = src;
      document.head.appendChild(mf);
    }
  }, [src]);

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo/>
        <SidebarGroup label="Search" icon={<SearchIcon/>} to="/search">
          <SidebarSearchModal/>
        </SidebarGroup>
        <SidebarDivider/>
        <SidebarGroup label="Menu" icon={<MenuIcon/>}>
          {/* Global nav, not org-specific */}
          <SidebarItem icon={HomeIcon} to="home" text="Home"/>
          <SidebarItem icon={ExploreIcon} to="explore" text="Explore"/>
          <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs"/>
          <SidebarItem icon={CatalogIcon} to="catalog" text="Catalog"/>
          <SidebarItem icon={LibraryBooks} to="docs" text="Docs"/>
          <SidebarItem icon={BuildIcon} to="toolbox" text="Utilities"/>
          {/* End global nav */}
        </SidebarGroup>
        <SidebarDivider/>
        <Shortcuts/>
        <SidebarSpace/>
        <SidebarDivider/>
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar/>}
          to="/settings"
        >
          <SidebarSettings/>
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
