/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Star from '@material-ui/icons/Star';
import React from 'react';
import {DefaultProviderSettings, ProviderSettingsItem} from '@backstage/plugin-user-settings';
import {
  configApiRef,
  useApi
} from '@backstage/core-plugin-api';
import {velaDevAuthApiRef, velaLiveAuthApiRef, velaPreviewAuthApiRef} from './apis';

/** @public */
export const CustomProviderSettings = () => {
  const configApi = useApi(configApiRef);
  const providersConfig = configApi.getOptionalConfig('auth.providers');
  const configuredProviders = providersConfig?.keys() || [];
  return (
    <>
      <DefaultProviderSettings configuredProviders={configuredProviders}/>
      <ProviderSettingsItem
        title="Kubevela Dev"
        description="Provides authentication towards Kubevela Dev APIs"
        apiRef={velaDevAuthApiRef}
        icon={Star}
      />
      <ProviderSettingsItem
        title="Kubevela Preview"
        description="Provides authentication towards Kubevela Preview APIs"
        apiRef={velaPreviewAuthApiRef}
        icon={Star}
      />
      <ProviderSettingsItem
        title="Kubevela Live"
        description="Provides authentication towards Kubevela Live APIs"
        apiRef={velaLiveAuthApiRef}
        icon={Star}
      />
    </>
  );
};
