import axios from 'axios';
import ObservableImpl from 'zen-observable';
import {v4 as uuid} from 'uuid';
import {DiscoveryApi, IdentityApi} from '@backstage/core-plugin-api';
import {Shortcut, ShortcutApi} from '@backstage-community/plugin-shortcuts';
import {pageTheme} from '@bdrck/portal-theme';

export const userSettingsBucket = 'central-station';
export const shortcutsKey = 'shortcuts';

export class UserSettingsShortcuts implements ShortcutApi {
  private subscribers = new Set<ZenObservable.SubscriptionObserver<Shortcut[]>>();

  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly identityApi: IdentityApi
  ) {
  }

  shortcut$() {
    return this.observable;
  }

  get() {
    return [];
  }

  async add(shortcut: Omit<Shortcut, 'id'>) {
    const shortcuts = await this.getShortcuts();
    shortcuts.push({...shortcut, id: uuid()});

    await this.updateShortcuts(shortcuts);
  }

  async remove(id: string) {
    const result = await this.getShortcuts();
    await this.updateShortcuts(result.filter((shortcut: { id: string; }) => shortcut.id !== id));
  }

  async update(updatedShortcut: Shortcut) {
    const result = await this.getShortcuts();
    const shortcuts = result.filter((shortcut: { id: string; }) => shortcut.id !== updatedShortcut.id);
    shortcuts.push(updatedShortcut);

    await this.updateShortcuts(shortcuts);
  }

  getColor(url: string) {
    const basePath = url.split('/')[1];
    const type = basePath === 'docs' ? 'documentation' : basePath;

    const theme = type in pageTheme ? type : 'other';

    return pageTheme[theme].colors[0];
  }

  private readonly observable = new ObservableImpl<Shortcut[]>(subscription => {
    this.observeShortcuts(subscription);
    this.subscribers.add(subscription);

    return () => {
      this.subscribers.delete(subscription);
    };
  });

  private async notify() {
    for (const subscription of this.subscribers) {
      await this.observeShortcuts(subscription);
    }
  }

  private async observeShortcuts(subscription: ZenObservable.SubscriptionObserver<Shortcut[]>) {
    let shortcuts = await this.getShortcuts();
    shortcuts = shortcuts.sort((a, b) => {
      return (a.title >= b.title ? 1 : -1);
    });
    subscription.next(shortcuts);
  }

  private async getShortcuts(): Promise<Shortcut[]> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const shortcutsUrl = await this.getShortcutsUrl();
    return axios.get(
      shortcutsUrl,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
      }
    ).then(response => {
      return response.data.value;
    }).catch(() => {
      return [];
    });
  }

  private async updateShortcuts(listOfShortcuts: Object[]) {
    const { token: idToken } = await this.identityApi.getCredentials();
    const shortcutsUrl = await this.getShortcutsUrl();
    await axios.put(
      shortcutsUrl,
      JSON.stringify({value: listOfShortcuts}),
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': `Bearer ${idToken}`
        }
      }
    );
    await this.notify();
  }

  private async getShortcutsUrl() {
    const baseUrl = await this.discoveryApi.getBaseUrl('user-settings');
    return `${baseUrl}/buckets/${userSettingsBucket}/keys/${shortcutsKey}`;
  }
}
