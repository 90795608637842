import React from 'react';
import {ToolExplorerContent} from './ToolExplorerContent';
import {Content, Header, Page} from '@backstage/core-components';

export const ExplorePage = () => {
  return (
    <Page themeId="home">
      <Header
        title="Explore the Bedrock ecosystem"
        subtitle="Discover solutions available in your ecosystem"
      />
      <Content>
        <ToolExplorerContent/>
      </Content>
    </Page>

  );
};

export const explorePage = <ExplorePage/>;
