import React, {useEffect, useState} from 'react';

import {
  Content,
  ContentHeader,
  EmptyState,
  ItemCardGrid,
  Progress,
  SupportButton,
  WarningPanel
} from '@backstage/core-components';

import {useApi} from '@backstage/core-plugin-api';
import {ToolCard} from './ToolCard';
import {Input, InputAdornment} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import {exploreApiRef} from '@backstage-community/plugin-explore';
import {ExploreTool} from '@backstage-community/plugin-explore-common';

type ToolExplorerContentProps = {
  title?: string
};

type ExplorerState = {
  tools: ExploreTool[]
  loading: boolean
  error: boolean
}

export const ToolExplorerContent = ({title}: ToolExplorerContentProps) => {
  const [filter, setFilter] = useState('');
  const [state, setState] = useState<ExplorerState>({tools: [], loading: true, error: false});
  const Body = () => {
    const exploreApi = useApi(exploreApiRef);

    useEffect(() => {
      const getTools = async () => {
        const tools = (await exploreApi.getTools())?.tools.sort((a, b) => a.title.localeCompare(b.title));
        setState({tools, loading: false, error: false});
      };
      if (state.tools.length === 0) {
        getTools().catch(() => {
          setState((prevState) => ({...prevState, error: true}));
        });
      }
    }, [exploreApi]);

    if (state.loading) {
      return <Progress/>;
    }

    if (state.error) {
      return <WarningPanel title="Failed to load tools"/>;
    }

    if (!state.tools?.length) {
      return (
        <EmptyState
          missing="info"
          title="No tools to display"
          description="You haven't added any tools yet."
        />
      );
    }

    const filterTools = (tool: ExploreTool) => {
      return tool.title.toLowerCase().includes(filter.toLowerCase()) ||
        (tool.description ?? '').toLowerCase().includes(filter.toLowerCase()) ||
        (tool.tags?.filter((tag) => {
          return tag.toLowerCase().includes(filter.toLowerCase());
        }).length ?? 0) > 0;
    };

    return (
      <ItemCardGrid>
        {state.tools.filter((tool) => {
          return filterTools(tool);
        }).map((tool, index) => (
          <ToolCard key={index} card={tool}/>
        ))}
      </ItemCardGrid>
    );
  };
  return (
    <Content noPadding>
      <ContentHeader title={title ?? 'Tools'}>
        <Input
          startAdornment={
            <InputAdornment position="start">
              <FilterListIcon/>
            </InputAdornment>
          }
          type="search"
          placeholder="Filter"
          onChange={e => setFilter(e.target.value)}
        />
        <SupportButton>Discover the tools in your ecosystem.</SupportButton>
      </ContentHeader>
      <Body/>
    </Content>
  );
};
